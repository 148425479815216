import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import setFavicons from "./utils/create-favicon";
import componentLoader from "./utils/component-loader";
import DatadogRum from "./components/datadog-rum";
import { QUERY_PARAMS } from "./config";

// Because we can have # in the callback redirect.
if (QUERY_PARAMS.path) window.location.hash = `#/${QUERY_PARAMS.path}`;
if (window.location.hash === "#_=_") window.location.hash = "";

let EntryPoint;
switch (import.meta.env.VITE_ENTRY_POINT) {
  case "LOGIN":
    EntryPoint = componentLoader(() => import("./entry-points/login"));
    break;
  case "PASSWORD_RESET":
    EntryPoint = componentLoader(() => import("./entry-points/password-reset"));
    break;
  case "POST_LOGIN":
    EntryPoint = componentLoader(() => import("./entry-points/post-login"));
    break;
  default:
    throw new Error(`Unknown entry point ${import.meta.env.VITE_ENTRY_POINT}`);
}

setFavicons();

(async () => {
  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <Suspense fallback={<div className="loading">Loading...</div>}>
        <EntryPoint />
        <DatadogRum />
      </Suspense>
    </React.StrictMode>,
  );
})();
