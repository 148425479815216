import { lazy, ComponentType, JSX } from "react";

const MAX_RELOAD_ATTEMPTS = 5;
const TIME_BETWEEN_ATTEMPTS = 500;

type ComponentToLoad = () => Promise<{ default: (props?: any) => JSX.Element }>;

export const load = (
  component: ComponentToLoad,
  loadingAttempts: number = 0,
): Promise<{ default: ComponentType<any> }> =>
  new Promise((resolve, reject) => {
    component()
      .then(resolve)
      .catch((err) => {
        setTimeout(() => {
          if (loadingAttempts === MAX_RELOAD_ATTEMPTS) {
            reject(err);
          }

          load(component, loadingAttempts + 1).then(resolve, reject);
        }, TIME_BETWEEN_ATTEMPTS);
      });
  });

const componentLoader = (component: ComponentToLoad) =>
  lazy(() => load(component));

export default componentLoader;
