const sharedConfig = (get: ImportMetaEnv) => {
  // Auth0 base domain
  const auth0Domain = {
    qa: "login.qa.alterramtnco.com",
    uat: "login.uat.alterramtnco.com",
    production: "login.alterramtnco.com",
    default: get.VITE_AUTH0_DOMAIN,
  }[(get.VITE_DEPLOY_TARGET as string) || "default"];

  // Auth0 post login client id
  const auth0PostLoginClientId = {
    qa: "3cVUamBXITvRAo54sdFqwsuzAY8WobP9",
    uat: "EF6cw79WBHPBVVcdbWFZ72YqfZbfmJCo",
    production: "CyykPObNnxFjeqiSD0EdpVrL5SBf1H20",
    default: get.VITE_AUTH0_POST_LOGIN_CLIENT_ID,
  }[(get.VITE_DEPLOY_TARGET as string) || "default"];

  const translationPreloadUrl = {
    qa: "https://main--alterra-login-translations.netlify.app/en.json",
    uat: "https://main--alterra-login-translations.netlify.app/en.json",
    production: "https://alterra-login-translations.netlify.app/en.json",
    default: "/translations/en.json",
  }[(get.VITE_DEPLOY_TARGET as string) || "default"];

  return { auth0Domain, auth0PostLoginClientId, translationPreloadUrl };
};

export default sharedConfig;
